import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import RiverflowService from '../../services/riverflow.service'


function* getRiverflowList(action) {
  try {
    
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const reservoir = action.payload?.type;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, reservoir, search, status};
      console.log('getRiverflowList sagas============ ',params);
      const response = yield RiverflowService.listRiverflow(params);
      //console.log('getContentList response userSagas============ ',response);
      if (response?.data) { 
        yield put(actions.getRiverflowListSuccess(response.data));
      } else {
        yield put(actions.getRiverflowListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getRiverflowListFailure(error));
  }
}

function* getRiverflowsPak(action) {
  try {
    
    if (action.payload) { 
      const reservoir = action.payload?.type; 
      const status = action.payload?.statusType;
      let params = { reservoir,   status};
      console.log('riverflowsPak sagas============ ',params);
      const response = yield RiverflowService.riverflowsPak(params);
      //console.log('getContentList response userSagas============ ',response);
      if (response?.data) { 
        yield put(actions.getRiverflowListSuccess(response.data));
      } else {
        yield put(actions.getRiverflowListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getRiverflowListFailure(error));
  }
}

function* manageRiverflow(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create; 
    if (create) {
      // create user flow
      delete payload._id
    }
    console.log('manageRiverflow sagas============ ',payload);
    response = yield RiverflowService.manageRiverflow(payload);
    //console.log('managePost response contentSagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data contentSagas ',response.data);
      yield put(actions.manageRiverflowSuccess(response.data));
    } else {
      yield put(actions.manageRiverflowFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.manageRiverflowFailure(error));
  }
}
function* getRiverflowsPakSaga() {
  yield takeEvery(actionsTypes.GET_RIVERFLOWS_PAK_START, getRiverflowsPak);
}

function* getRiverflowListSaga() {
  yield takeEvery(actionsTypes.GET_RIVERFLOW_LIST_START, getRiverflowList);
}

function* manageRiverflowSaga(){
  yield takeEvery(actionsTypes.MANAGE_RIVERFLOW_START, manageRiverflow);
}

export function* riverflowSaga() {
  yield all([fork(getRiverflowListSaga),fork(manageRiverflowSaga),fork(getRiverflowsPakSaga)]);
}
