import React from 'react';
import Pagination from "@material-ui/lab/Pagination";
import '../styles.scss'
import {useStyles} from "../cpManagementDataTableStyle";

function Index(props) {
  const {rowsPerPageOptionList, perPage, onPageSizeChange, totalRowsInDb, pageNo, onPageChangeHandler} = props;
  const classes = useStyles();

  return (
    <Pagination
      className={classes.pagination}
      variant="outlined" shape="rounded"
      count={Math.ceil(totalRowsInDb/perPage)}
      // rowsPerPage={perPage}
      // onChangeRowsPerPage={onPageSizeChange}
      page={pageNo}
      onChange={onPageChangeHandler}
    />
  );
}

export default Index;
