import React from 'react';
import {Dashboard} from "../../components";
import * as actions from "../../store/actions";
import {connect} from "react-redux";

function Index(props) { 

  let {dashboardReducer: {dashboard, loading, error}} = props;

  return (
    <div className='mb-20'>
      { <Dashboard   dashboard={dashboard} loading={loading} {...props}/>  }
    </div>
  );
}

const mapStateToProps = ({dashboardReducer = {}, servicesReducer = {}}) => {
  return {dashboardReducer, servicesReducer};
};
const mapDispatchToProps = (dispatch) => {
  return { 
    confirmationHandler: (data) => dispatch(actions.confirmationHandler(data)), 
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
