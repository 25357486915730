import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  user: null,
  error: null,
  success: null, 
  loggedInSuccess:null,
  queryList:null, 
  queryListError:null,
  successConfig:null,
  dataGetConfig:null,
  successGetConfig:null,
};

export const authReducer = (state = initialState, action) => {
  console.log('action.type authReducer === ',action.payload);
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return {...state, loading: true, error: null, success: null};
    case actionTypes.LOGIN_SUCCESS:
      return {...state, loading: false, error: null, success: action.payload.message,loggedInSuccess:true};
    case actionTypes.LOGIN_FAILURE:
      return {...state, loading: false, error: action.payload, success: null};
    
    case actionTypes.SIGNUP_START:
      return {...state, loading: true, error: null, success: null, user: null};
    case actionTypes.SIGNUP_SUCCESS:
      return {...state, loading: false, error: null, success: action.payload.success, user: action.payload.user};
    case actionTypes.SIGNUP_FAILURE:
      return {...state, loading: false, error: action.payload,message:null, user: null};

    case actionTypes.LOGOUT:
      return {...state, loading: false, error: null, success: null, user: {}, loggedInSuccess:false};
    case actionTypes.LOGOUT_SUCCESS:
      return {...state, loading: false, error: null, success: null, user: null};

    case actionTypes.LOGIN_ERROR_HANDLER_SUCCESS:
      return {...state, loading: false, error: action.payload, success: action.payload,};

    case actionTypes.LIST_QUERY_START:
      return {...state, loading: true, error: null,  queryList:null, queryListError:null};

    case actionTypes.LIST_QUERY_SUCCESS:
      return {...state, loading: false, queryList:action.payload};

    case actionTypes.LIST_QUERY_FAILURE:
      return {...state, loading: false, error:action.payload, queryListError: action.payload,};

    case actionTypes.MANAGE_CONFIG_START:
      return {...state, loading: true, error: null, successConfig: null,};
    case actionTypes.MANAGE_CONFIG_SUCCESS:
      return {...state, loading: false, error: null, successConfig: action.payload.message,};
    case actionTypes.MANAGE_CONFIG_FAILURE:  
      return {...state, loading: false, error: action.payload,message:null,};

    case actionTypes.GET_CONFIG_START:
      return {...state, loading: true, error: null, successGetConfig: null,dataGetConfig:null};
    case actionTypes.GET_CONFIG_SUCCESS:
      return {...state, loading: false, error: null, successGetConfig: action.payload.success,dataGetConfig:action.payload};
    case actionTypes.GET_CONFIG_FAILURE:  
      return {...state, loading: false, error: action.payload,message:null,};
  
    
      


    default:
      return {
        ...state
      };
  }
};
