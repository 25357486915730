import * as actionTypes from "../actionTypes";

export const getContentList = (data) => {
    return {
        type: actionTypes.GET_CONTENT_LIST_START,
        payload: data,
    };
};
export const getContentListSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENT_LIST_SUCCESS,
        payload: data,
    };
};
export const getContentListFailure = (data) => {
    return {
        type: actionTypes.GET_CONTENT_LIST_FAILURE,
        payload: data,
    };
};
export const managePostSuccess = (data) => {
    //console.log('managePostSuccess ',data);
    return {
        type: actionTypes.MANAGE_CONTENT_SUCCESS,
        payload: data,
    };
};
export const managePostFailure = (data) => {
    return {
        type: actionTypes.MANAGE_CONTENT_FAILURE,
        payload: data,
    };
};
export const getContentData = (data) => {
    return {
        type: actionTypes.GET_CONTENT_DATA_START,
        payload: data,
    };
};
export const getContentDataSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENT_DATA_SUCCESS,
        payload: data,

    };
};
export const getContentDataFailure = (data) => {
    return {
        type: actionTypes.GET_CONTENT_DATA_FAILURE,
        payload: data,
    };
};

export const manageContent = (data) => {
    return {
        type: actionTypes.MANAGE_CONTENT_START,
        payload: data,
    };
};

export const updateContent = (data) => {
    return {
        type: actionTypes.UPDATE_CONTENT_START,
        payload: data,
    };
};
export const updateContentSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_CONTENT_SUCCESS,
        payload: data,
    };
};
export const updateContentFailure = (data) => {
    return {
        type: actionTypes.UPDATE_CONTENT_FAILURE,
        payload: data,
    };
};


export const deleteContent = (data) => {
    return {
        type: actionTypes.DELETE_CONTENT_START,
        payload: data,
    };
};
export const deleteContentSuccess = (data) => {
    return {
        type: actionTypes.DELETE_CONTENT_SUCCESS,
        payload: data,
    };
};
export const deleteContentFailure = (data) => {
    return {
        type: actionTypes.DELETE_CONTENT_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};

export const manageSeo = (data) => {
    return {
        type: actionTypes.MANAGE_SEO_START,
        payload: data,
    };
};

export const manageSeoSuccess = (data) => {
    return {
        type: actionTypes.MANAGE_SEO_SUCCESS,
        payload: data,
    };
};

export const manageSeoFailure = (data) => {
    return {
        type: actionTypes.MANAGE_SEO_FAILURE,
        payload: data,
    };
};


export const getSeo = (data) => {
    return {
        type: actionTypes.GET_SEO_START,
        payload: data,
    };
};

export const getSeoSuccess = (data) => {
    return {
        type: actionTypes.GET_SEO_SUCCESS,
        payload: data,
    };
};

export const getSeoFailure = (data) => {
    return {
        type: actionTypes.GET_SEO_FAILURE,
        payload: data,
    };
};
