import React from "react";
import Typography from '@material-ui/core/Typography';
import Chart from 'react-google-charts'
import { withRouter } from "react-router-dom";

function GraphRep(props) {
  console.log('in GraphRep');
  const { data } = props;
  const { rows } = data;
  let lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  let lineDataArr = [];
  let currDate = new Date();

  if (Array.isArray(rows)) {
    let sum = 0;
    let finalDataArray = []
    let percentageArray = []
    for (let i = 1; i < rows[0].length; i++) {
      for (let j = 1; j < rows.length; j++) {
        sum += rows[j][i];
      }
      finalDataArray.push([rows[0][i], sum])
    }
    let total = 0;
    finalDataArray.forEach(elem => total += elem[1]);
    finalDataArray.forEach(elem => {
      let percentage = (elem[1] / total) * 100;
      percentageArray.push([elem[0], percentage])
    });
    percentageArray.unshift(["Task", "Hours per Day"]);
    var pieData = percentageArray;

    lineDataArr.push(rows[0]);
    for (let i = 1; i < rows.length; i++) {
      currDate = new Date(rows[i][0]);
      if (currDate > lastMonthDate) {
        lineDataArr.push(rows[i]);
      }
    }
  }


  const LineData = lineDataArr;
  const LineChartOptions = {
    hAxis: {
      title: 'Date',
    },
    vAxis: {
      title: 'FLOWS (1000 X CS)',
    },
    series: {
      1: { curveType: 'function' },
    },
  }


  return (
    <div className="driver-main-page">
      {/* <div className="grph-box-outer"> */}
        <div className="grph-box-outer">
          <Typography className="grph-heading" align="left" > River Inflows and Outflows</Typography>
          <Chart
            width={'100%'}
            height={'410px'}
            chartType="PieChart"
            loader={<div>Loading ...</div>}
            data={pieData}
          />
        </div>
      {/* </div> */}
      {/* <hr /> */}
      {/* <Typography component="h1" variant="h5" align="center" style={{ marginTop: '60px' }}> Line Chart</Typography>    */}
      <div style={{ marginBottom: '50px' }} className="grph-box-outer">
        <Typography className="grph-heading" align="left" > River Flow Graph <span>(River flow information for latest 22 days)</span></Typography>
        <Chart
          width={'100%'}
          height={'410px'}
          chartType="LineChart"
          loader={<div>Loading ...</div>}
          data={LineData}
          options={LineChartOptions}
          rootProps={{ 'data-testid': '2' }}
        />
      </div>
      {/* <hr /> */}

    </div>
  );
}

export default withRouter(
  (GraphRep)
);
