import HttpService from "./http.service";

class GalleryService extends HttpService {
    list = (params) => this.get("asset/list-gallery", params); 
    manageVideoLink = (params) => this.post("asset/manage-video", params);   
    manageImage = (params) => this.post("asset/manage-image", params);
    deleteImage = (params) => this.post("asset/delete-image", params);
    manageFile = (params) => this.post("asset/manage-file", params);
    listFiles = (params) => this.get("asset/list-file", params); 
    deleteFile = (params) => this.post("asset/delete-file", params);


  
  
}

export default new GalleryService();
