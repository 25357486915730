import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import CategoryService from '../../services/category.service'


function* getParentCategoryList(action) {
  try {
      const response = yield CategoryService.listParentCategories(action.payload);
      console.log('getParentCategoryList response userSagas============ ',response);
      if (response?.status === 200) {
        console.log('response.data userSaga ',response.data);
        yield put(actions.getParentCategoryListSuccess(response.data.parentCategories));
      } else {
        yield put(actions.getParentCategoryListFailure("network error"));
      }
    }
    catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getParentCategoryListFailure(error));
  }
}

function* getChildCategoryList(action) {
  try {
    console.log('getCategoryList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const category = action.payload?.catName;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, category, search, status};

      const response = yield CategoryService.listChildCategories(params);
      console.log('getChildCategoryList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        console.log('response.data userSaga ',response.data);
        yield put(actions.getChildCategoryListSuccess(response.data));
      } else {
        yield put(actions.getChildCategoryListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getChildCategoryListFailure(error));
  }
}

function* manageCategory(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    console.log('manageCategory categorySagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    console.log('manageCategory sagas============ ',payload);
    response = yield CategoryService.manageCategory(payload);
    console.log('manageCategory response categorySagas============ ',response);
    if (response?.data.statusCode === 200) {
      console.log('response.data categorySagas ',response.data);
      yield put(actions.manageCategorySuccess(response.data));
    } else {
      yield put(actions.manageCategoryFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* getParentCategoryListSaga() {
  yield takeEvery(actionsTypes.GET_PARENT_CATEGORY_LIST_START, getParentCategoryList);
}

function* getChildCategoryListSaga() {
  yield takeEvery(actionsTypes.GET_CHILD_CATEGORY_LIST_START, getChildCategoryList);
}

function* manageCategorySaga(){
  yield takeEvery(actionsTypes.MANAGE_CATEGORY_START, manageCategory);
}

export function* categorySaga() {
  yield all([fork(getParentCategoryListSaga),fork(getChildCategoryListSaga),fork(manageCategorySaga)]);
}
