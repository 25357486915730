import React, { Suspense} from "react";
import {useStyles} from "../container/sidebar/styles";
import MySideBar from '../container/sidebar/MySidebar';
import {Loader} from "../components";
import {getUserDataFromLocalStorage} from "../../src/services/utils"; 
import { useHistory } from "react-router";

const MainLayout = (props) => {
  const history = useHistory();
  const classes = useStyles();
  // !getUserDataFromLocalStorage() ?? history.push({pathname: `/dashboard`});

  return (
    <div className={[classes.root, " sidebar-container "]}>
      <MySideBar sidebar={props.sidebar}/>
      <div className={classes.content}>
        <Suspense fallback={<Loader/>}>
          {props.children}
        </Suspense>
      </div>
    </div>
  );
};
export default MainLayout;
