import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import UserService from '../../services/user.service'
import AuthService from '../../services/auth.service'

function* getUsersList(action) {
  try {
    //console.log('getUsersList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const role = action.payload?.roleName;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, role, search, status};

      const response = yield UserService.list(params);
      //console.log('getUsersList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.getUsersListSuccess(response.data));
      } else {
        yield put(actions.getUsersListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getUsersListFailure(error));
  }
}

function* getUsersListSaga() {
  yield takeEvery(actionsTypes.GET_USERS_LIST_START, getUsersList);
}


function* updateUserStart(action) {
  console.log('In userUpdate authSagas.js ',action.payload);
  try {
    const response = yield UserService.editUser(
      action.payload
    );

    console.log('update user  response ',response.data);
    if (response.data?.statusCode==200) { 
      yield put(actions.updateUserSuccess(true));
    } else {
      yield put(actions.updateUserFailure(response.data.message));
    }
  } catch (err) {
    console.log('err userUpdate authSagas.js ', err)
    yield put(actions.updateUserFailure(err));
  }
}

function* updateUserStartSaga() {
  yield takeEvery(actionsTypes.UPDATE_USER_START, updateUserStart);
}

function* addNewUserStart(action) {
  console.log('In New user authSagas.js ',action.payload);
  try {
    const response = yield AuthService.signup(
      action.payload
    );

    console.log('New user  response ',response.data);
    if (response.data?.statusCode==201) { 
      yield put(actions.addNewUserSuccess(true));
    } else {
      yield put(actions.addNewUserFailure(response.data.message));
    }
  } catch (err) {
    console.log('err newUser authSagas.js ', err)
    yield put(actions.addNewUserFailure("Internal Server Error......"));
  }
}

function* addNewUserSaga() {
  yield takeEvery(actionsTypes.ADD_NEW_USER_START, addNewUserStart);
}


function* getUserPermissionList(action) {
  try {
    if (action.payload) {
      const response = yield UserService.listUserPermission(action.payload);
      if (response?.data?.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.listUserPermissionSuccess(response.data));
      } else {
        yield put(actions.listUserPermissionFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.listUserPermissionFailure(error));
  }
}

function* getUserPermissionListSaga() {
  yield takeEvery(actionsTypes.LIST_USER_PERMISSION_START, getUserPermissionList);
}


function* manageUserPermission(action) {
  try {
    if (action.payload) {
      const {data, params}=action.payload;
      const response = yield UserService.manageUserPermission(data, params);
      if (response?.data?.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.manageUserPermissionSuccess(response.data));
      } else {
        yield put(actions.manageUserPermissionFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.manageUserPermissionFailure(error));
  }
}

function* manageUserPermissionSaga() {
  yield takeEvery(actionsTypes.MANAGE_USER_PERMISSION_START, manageUserPermission);
}


function* getModuleList(action) {
  try {
    if (action.payload) {
      const response = yield UserService.listModules(action.payload);
      if (response?.data?.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.listModuleSuccess(response.data));
      } else {
        yield put(actions.listModuleFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.listModuleFailure(error));
  }
}

function* listModuleSaga() {
  yield takeEvery(actionsTypes.LIST_MODULE_START, getModuleList);
}


function* listLoginHistory(action)  {
  try {
    //console.log('getUsersList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const user_id = action.payload?.user_id;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, user_id, search, status};

      const response = yield UserService.listLoginHistory(params);
      console.log('getUsersList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.listLoginHistorySuccess(response.data));
      } else {
        yield put(actions.listLoginHistoryFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.listLoginHistoryFailure(error));
  }
}

function* listLoginHistorySaga() {
  yield takeEvery(actionsTypes.LIST_LOGIN_HISTORY_START, listLoginHistory);
}

export function* userSaga() {
  yield all([fork(getUsersListSaga), fork(updateUserStartSaga), fork(addNewUserSaga), fork(getUserPermissionListSaga), fork(manageUserPermissionSaga), fork(listModuleSaga), fork(listLoginHistorySaga) ]);
}
