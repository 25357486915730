import {all} from "redux-saga/effects";
import {authSaga} from "./authSagas";
import {dashboardSaga} from "./dashboardSagas";
import {userSaga} from "./userSagas";
import {categorySaga} from "./categorySagas";
import {contentSaga} from "./contentSagas";
import {gallerySaga} from "./gallerySagas";
import {fileUploadSaga} from "./fileUploadSaga";
import {businessSaga} from "./businessSagas";
import {riverflowSaga} from "./riverflowSagas";
import {empCornerSaga} from "./empCornerSaga";
export function* rootSaga(getState) {
  yield all([
    authSaga(),
    dashboardSaga(),
    userSaga(),
    categorySaga(),
    contentSaga(),
    gallerySaga(),
    fileUploadSaga(),
    businessSaga(),
    riverflowSaga(),
    empCornerSaga()
  ]);
}
