import './App.css';
import './assets/index.scss'
import Routes from "./routes/routes";
import {Provider} from 'react-redux';
import PersistedStore from "./store";
import ReactGA from 'react-ga4';
import axios from "axios";
import {logout} from "../src/store/actions";
import { TRACKING_ID } from './environment/index.js';
ReactGA.initialize(TRACKING_ID);

const store = PersistedStore.getDefaultStore().store;

const App = () => {

  return (
    <Provider store={store}>
      <Routes/>
    </Provider>)
}

const {dispatch} = store;
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
      dispatch(logout())
      // handle error: inform user, go to login, etc
  } else {
      return Promise.reject(error);
  }
});


export default App;

// https://www.codementor.io/blog/react-optimization-5wiwjnf9hj#.YbD42UeP0l4.linkedin
