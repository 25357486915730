import {combineReducers} from "redux";
import {authReducer} from "./auth";
import {dashboardReducer} from "./dashboard";
import {usersReducer} from "./usersReudcer"; 
import {categoryReducer} from "./categoryReducer"; 
import {contentReducer} from "./contentReudcer"; 
import {assetReducer} from "./assetReducer"; 
import {confirmationReducer} from './confirmationReducer'; 
import {businessReducer} from './businessReducer'
import {riverflowReducer} from './riverflowReducer'
import {empCornerReducer} from './empCornerReducer'
import {helpReducer} from './helpReducer'; 
export const rootReducer = combineReducers({
  authReducer, dashboardReducer, usersReducer,categoryReducer,contentReducer,assetReducer,confirmationReducer, helpReducer, businessReducer, empCornerReducer,riverflowReducer
});
