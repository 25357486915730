export const dashboardPage = [
    {intro: 'Wapda content management system.', label: "Intro"},
    {dash: 'Dashboard shows river flow graph data.', label: "Dash"},
    {sideBar: 'The side bar menu contains all the tabs form where various sections of the website.', label: "Sidebar"}
]

export const userHomePage = [ 

]

export const userManagement = [ 
    {intro: 'Admin can add  employees & stakeholders.', label: "Intro"},
    {dash: 'Can assign stakeholders projects.', label: "permissions"},
] 

export const addUser = [ 
    {intro: 'Fill in the form data.', label: "Intro"},
    {dash: 'Click on submit button. User will be added successfully.', label: "Submit"},
] 

export const userPermission = [ 
    {intro: 'Assign stakeholder project content with respect to category.', label: "Intro"},
] 

export const userHistory = [ 
    {intro: 'User previous login hisotry.', label: "Intro"},
] 

export const projectManagement = [ 
    {intro: 'Create and edit list of projects.', label: "Intro"},
    {intro: 'User can add gallery image.', label: "Image"},
    {intro: 'User can add doc & files.', label: "File"},
    {intro: 'User can add search engine data.', label: "SEO"},
] 

export const contentManagement = [ 
    {intro: 'Manage Content against Home page, WAPDA regulations, sports section, About Us page, Research papers, Investors & CSR .', label: "Intro"},
    {intro: 'User can add gallery image.', label: "Image"},
    {intro: 'User can add Doc & files.', label: "File"},
    {intro: 'User can add search engine data.', label: "SEO"},
] 

export const editContent = [ 
    {intro: 'Edit post content form fields .', label: "Intro"},
    {intro: 'Manage post content data through rich text editor.', label: "Text Editor"},
] 

export const newsViews  = [ 
    {intro: 'Manage Content against news.', label: "Intro"},
    {intro: 'User can add gallery image.', label: "Image"},
    {intro: 'User can add doc & files.', label: "File"},
    {intro: 'User can add search engine data.', label: "SEO"},
]

export const galleryManagement = [ 
    {intro: 'Manage images and video against content.', label: "Intro"},
    {intro: 'Add images & videos against a specific content.', label: "Actions"},
]

export const businessManagement = [ 
    {intro: 'Create and edit list of business content.', label: "Intro"},
    {intro: 'User can add doc & files.', label: "File"},
] 

export const careerManagement = [ 
    {intro: 'Create and edit list of job/posting for careers content.', label: "Intro"},
    {intro: 'User can add gallery image.', label: "Image"},
    {intro: 'User can add doc & files.', label: "File"},
    {intro: 'User can add search engine data.', label: "SEO"},
] 

export const riverFlowManagement = [ 
    {intro: 'Manage river flow stream data.', label: "Intro"},
    {intro: 'Graph & Piechart is created with respect to it.', label: "Representation"},
    {intro: 'User can add flow data against specified stream flow.', label: "Create"},
    {intro: 'User can edit existing stream flow data entry.', label: "Edit"},
] 

export const addRiverFlow = [ 
    {intro: 'Fill in the form data.', label: "Intro"},
    {dash: 'Click on submit button. Entry will be added successfully.', label: "Submit"},
] 

export const empCorner = [ 
    {intro: 'Listed Employee related data like transfers, allowances, pension & exam notifications.', label: "Intro"},
] 

export const editEmpCorner = [ 
    {intro: 'Fill in the form data.', label: "Intro"},
    {dash: 'Click on submit button. User will be added successfully.', label: "Submit"},
] 

export const riverFlowPakistan = [ 
    {intro: 'River flow stream data, graph and piechart is ploted with respect to it.', label: "Intro"},
    {intro: 'Yearly data is listed against specified stream.', label: "Year"},
] 

export const generalInfo = [ 
    {intro: 'General business information & docs are listed here.', label: "Intro"},
    {intro: 'Attachments are previewed.', label: "Preview"},
    {intro: 'Attachments are downloaded.', label: "Download"},
] 

export const queries = [ 
    {intro: 'This section list investor queries.', label: "Intro"},
] 

export const globalConfig = [ 
    {intro: 'This section manages the contact details in footer of website like address, social media handle links and other details.', label: "Intro"},
] 

export const seoManagement = [ 
    {intro: 'User can add search engine data.', label: "Intro"},
] 

export const fileHelp = [ 
    {intro: 'User can add doc & files.', label: "Intro"},
] 

export const creatUserPage = [
    {createUser: 'Create user by completing the profile.', label: "Create User"},
    {Role: 'Assign Role to the user.', label: "Role"},
]
    