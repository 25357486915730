import * as actionTypes from "../actionTypes";

export const getGalleryList = (data) => {
    return {
        type: actionTypes.GET_GALLERY_LIST_START,
        payload: data,
    };
};
export const getGalleryListSuccess = (data) => {
    return {
        type: actionTypes.GET_GALLERY_LIST_SUCCESS,
        payload: data,
    };
};
export const getGalleryListFailure = (data) => {
    return {
        type: actionTypes.GET_GALLERY_LIST_FAILURE,
        payload: data,
    };
};
export const managePostSuccess = (data) => {
    //console.log('managePostSuccess ',data);
    return {
        type: actionTypes.MANAGE_GALLERY_SUCCESS,
        payload: data,
    };
};
export const managePostFailure = (data) => {
    return {
        type: actionTypes.MANAGE_GALLERY_FAILURE,
        payload: data,
    };
};
export const getGalleryData = (data) => {
    return {
        type: actionTypes.GET_GALLERY_DATA_START,
        payload: data,
    };
};
export const getGalleryDataSuccess = (data) => {
    return {
        type: actionTypes.GET_GALLERY_DATA_SUCCESS,
        payload: data,

    };
};
export const getGalleryDataFailure = (data) => {
    return {
        type: actionTypes.GET_GALLERY_DATA_FAILURE,
        payload: data,
    };
};

export const manageGallery = (data) => {
    return {
        type: actionTypes.MANAGE_GALLERY_START,
        payload: data,
    };
};

export const manageGallerySuccess = (data) => {
    return {
        type: actionTypes.MANAGE_GALLERY_SUCCESS,
        payload: data,
    };
};

export const manageGalleryFailure = (data) => {
    return {
        type: actionTypes.MANAGE_GALLERY_FAILURE,
        payload: data,
    };
};

export const updateGallery = (data) => {
    return {
        type: actionTypes.UPDATE_GALLERY_START,
        payload: data,
    };
};
export const updateGallerySuccess = (data) => {
    return {
        type: actionTypes.UPDATE_GALLERY_SUCCESS,
        payload: data,
    };
};
export const updateGalleryFailure = (data) => {
    return {
        type: actionTypes.UPDATE_GALLERY_FAILURE,
        payload: data,
    };
};


export const deleteGallery = (data) => {
    return {
        type: actionTypes.DELETE_GALLERY_START,
        payload: data,
    };
};
export const deleteGallerySuccess = (data) => {
    return {
        type: actionTypes.DELETE_GALLERY_SUCCESS,
        payload: data,
    };
};
export const deleteGalleryFailure = (data) => {
    return {
        type: actionTypes.DELETE_GALLERY_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};

export const manageVideo= (data) => {
    return {
        type: actionTypes.MANAGE_VIDEO_START,
        payload: data,
    };
};

export const manageVideoSuccess = (data) => {
    return {
        type: actionTypes.MANAGE_VIDEO_SUCCESS,
        payload: data,
    };
};

export const manageVideoFailure = (data) => {
    return {
        type: actionTypes.MANAGE_VIDEO_FAILURE,
        payload: data,
    };
};

export const manageFile = (data) => {
    return {
        type: actionTypes.MANAGE_FILE_START,
        payload: data,
    };
};

export const manageFileSuccess = (data) => {
    return {
        type: actionTypes.MANAGE_FILE_SUCCESS,
        payload: data,
    };
};

export const manageFileFailure = (data) => {
    return {
        type: actionTypes.MANAGE_FILE_FAILURE,
        payload: data,
    };
};

export const getFileList = (data) => {
    return {
        type: actionTypes.GET_FILE_LIST_START,
        payload: data,
    };
};
export const getFileListSuccess = (data) => {
    return {
        type: actionTypes.GET_FILE_LIST_SUCCESS,
        payload: data,
    };
};
export const getFileListFailure = (data) => {
    return {
        type: actionTypes.GET_FILE_LIST_FAILURE,
        payload: data,
    };
};

export const deleteFile = (data) => {
    return {
        type: actionTypes.DELETE_FILE_START,
        payload: data,
    };
};
export const deleteFileSuccess = (data) => {
    return {
        type: actionTypes.DELETE_FILE_SUCCESS,
        payload: data,
    };
};
export const deleteFileFailure = (data) => {
    return {
        type: actionTypes.DELETE_FILE_FAILURE,
        payload: data,
    };
};

