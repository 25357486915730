import * as actionTypes from "../actionTypes";

export const getBusinessList = (data) => {
    return {
        type: actionTypes.GET_BUSINESS_LIST_START,
        payload: data,
    };
};
export const getBusinessListSuccess = (data) => {
    return {
        type: actionTypes.GET_BUSINESS_LIST_SUCCESS,
        payload: data,
    };
};
export const getBusinessListFailure = (data) => {
    return {
        type: actionTypes.GET_BUSINESS_LIST_FAILURE,
        payload: data,
    };
};

export const manageBusinessSuccess = (data) => {
    //console.log('managePostSuccess ',data);
    return {
        type: actionTypes.MANAGE_BUSINESS_SUCCESS,
        payload: data,
    };
};

export const manageBusinessFailure = (data) => {
    return {
        type: actionTypes.MANAGE_BUSINESS_FAILURE,
        payload: data,
    };
};

export const manageBusiness = (data) => {
    return {
        type: actionTypes.MANAGE_BUSINESS_START,
        payload: data,
    };
};


export const businessMessageHandler = () => {
    return {
        type: actionTypes.BUSINESS_MESSAGE_HANDLER,
        payload: null,
    };
};
