export const useStyles = theme => ({
      root: {
        height: '100vh',
      },
      logoImage:{
        width: "10% !important",
        height: "auto !important"
      },
      image: {
        display: 'flex',
        height:500,
        backgroundColor:"#f00"
      },
      paper: {
        margin: theme.spacing(2, 20),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
      },
      submit: {
        margin: theme.spacing(3, 8, 2),
      },
      fullWidth:{
        width:"100%"
      },
      loginForm:{
        "& .MuiFormControl-fullWidth": {
          marginTop:'10px'
        }
      }
});