import {makeStyles} from "@material-ui/core/styles";
import { downloadIcon } from "../../assets/images/images";

export const useStyles = makeStyles((theme) => ({

  dwnldRegulationIcon:{
    display: "inline-block",
    backgroundImage: `url("${downloadIcon}")`,
    backgroundRepeat: "no-repeat",
    width: "30px",
    height: "30px",
},

  root: {
    width: "100%",
    position:'relative'
    // background:'lightblue',
    // padding:10
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: 'transparent'
  },
  table: {
    minWidth: 750,
    color: "#0D0D39",
    '& .MuiTableRow-root':{
      background: '#FFFFFF',
      border: '1px solid #ECEDF2',
      borderRadius: '10px',
      marginBottom: '22px'
    }
  },
  rowSpacer:{
    background: '#f5f8fa !important',
    '& .MuiTableCell-root':{
      padding: '10px'
    }
  },
  tableContainer: {
    maxHeight:460
  },
  userTableContainer: {
    maxHeight:390
  },
  tableHead: {
    border: "none",
    borderTop: "1px solid #e3ebf6",
    borderBottom: "1px solid #e3ebf6",
    fontWeight: "bold",
    color: "#676767",
    textTransform: "capitalize",
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5,
  },
  tableCell: {
    border: "none",
    opacity: 0.9,
    fontSize: 12,
    color: "#000",
    textTransform: "capitalize",
  },
  actionCell: {
    opacity:1,
    display:'flex',
    alignItems:'center'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  actionIcon:{
    fontSize:20,
    // marginLeft:10,
    marginLeft:10,
  },
  marginTop20:{
    marginTop:20
  },
  marginTop30:{
    marginTop:30
  },
  pagination: {
    marginTop:30,
    marginBottom:30,
    '& .Mui-selected': {
        backgroundColor: '#0E97FF !important',
        color:'#fff !important',
    },
    '& .MuiPaginationItem-outlined': {
        border: '1px solid #0E97FF',
    },
    '& .MuiPaginationItem-root': {
        color: '#0E97FF',
    },
    '& .MuiPaginationItem-page:hover': {
        backgroundColor: 'rgb(14 151 255 / 58%);',
        color: '#fff',
    }
  }
}));
