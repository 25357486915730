import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainLayoutRoutes from "./mainRoutes";
import SignIn from "../container/auth/signIn/signIn"; 
import {Confirmation, HelpModal} from "../components"; 
import Dashboard from "../container/homePage";
import HttpService from "../services/http.service";
import {C_OTC_STORAGE} from "../environment";
import ReactGA from 'react-ga4';
import { TRACKING_ID } from '../environment/index.js';

const Users = React.lazy(() => import('../container/users'));
const Content = React.lazy(() => import('../container/content'));
const Project = React.lazy(() => import('../container/project'));
const Career = React.lazy(() => import('../container/career'));
const Business = React.lazy(() => import('../container/business'));
const Riverflow = React.lazy(() => import('../container/riverflow'));
const RiverflowPakistan = React.lazy(() => import('../container/riverflow/preview'));
const EmployeeCorner = React.lazy(() => import('../container/emp_corner'));
const CreateUser = React.lazy(() => import('../container/users/createUser/createUser'));
const LoginHistory = React.lazy(() => import('../container/users/userLoginHistory'));
const UserPermission = React.lazy(() => import('../container/users/userPermission/userPermission'));
const UserModule = React.lazy(() => import('../container/users/userModule/userModule'));

const Queries = React.lazy(() => import('../container/queries'));
const CreateContent = React.lazy(() => import('../container/content/create/index'));
const CreateBusiness = React.lazy(() => import('../container/business/create/index'));
const CreateRiverflow = React.lazy(() => import('../container/riverflow/create/index'));
const CreateEmployeeCorner = React.lazy(() => import('../container/emp_corner/create/index'));
const Category = React.lazy(() => import('../container/category'));
const CreateCategory = React.lazy(() => import('../container/category/create/index'));
const NewsViews = React.lazy(() => import('../container/newsViews'));
const Gallery = React.lazy(() => import('../container/gallery/index'));
const AddImages = React.lazy(() => import('../container/gallery/addImages/index'));
const AddFiles = React.lazy(() => import('../container/uploadFile/addfiles/index'));
const AddSeo = React.lazy(() => import('../container/seo/create/index'));
const GlobalConfig = React.lazy(() => import('../container/globalConfig/index'));


 
ReactGA.initialize(TRACKING_ID);
function Routes() {
  var url = document.URL.split("/");
  try{

    if(url.length>4){ 
      let token=url[3];
      let userDetail=JSON.parse(decodeURI(url[4])); 
      if(userDetail._id){
        HttpService.setToken(token);//set token in header 
        HttpService.setUser(userDetail._id);// set userId and role in header 
        localStorage.setItem("token",token);
        localStorage.setItem('user', JSON.stringify(userDetail)); 
        localStorage.setItem(C_OTC_STORAGE, JSON.stringify(userDetail));
        let domLink=url[0]+"//"+url[2]+"/dashboard"; 
        window.location.replace(domLink);
      }
    }
  }catch(e){
    console.log("exc",e)
  }
    
  
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={SignIn}/>
        <Route path="/login" exact component={SignIn}/> 

        <MainLayoutRoutes exact path="/dashboard" render={matchprops => (<Dashboard {...matchprops} />)} 
        routeName={'dashboard'}/>
        <MainLayoutRoutes exact path="/users" render={matchprops => (<Users {...matchprops} />)} routeName={"User Management"}/>
        <MainLayoutRoutes exact path="/users/update" render={matchprops => (<CreateUser {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/createNew" render={matchprops => (<CreateUser {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/loginHistory" render={matchprops => (<LoginHistory {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/userPermission" render={matchprops => (<UserPermission {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/userModule" render={matchprops => (<UserModule {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/queries" render={matchprops => (<Queries {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/manageBusiness" render={matchprops => (<Business {...matchprops} />)} routeName={"Business Management"}/>
        <MainLayoutRoutes exact path="/business" render={matchprops => (<Business {...matchprops} />)} routeName={"General Information"}/>
        <MainLayoutRoutes exact path="/business/:id" render={matchprops => (<CreateBusiness {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/business/createNew" render={matchprops => (<CreateBusiness {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/riverflow" render={matchprops => (<Riverflow {...matchprops} />)} routeName={"Riverflow Management"}/>
        <MainLayoutRoutes exact path="/riverflowPakistan" render={matchprops => (<RiverflowPakistan {...matchprops} />)} routeName={"Riverflow in Pakistan"}/>
        <MainLayoutRoutes exact path="/riverflow/:id" render={matchprops => (<CreateRiverflow {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/riverflow/createNew" render={matchprops => (<CreateRiverflow {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/employee" render={matchprops => (<EmployeeCorner {...matchprops} />)} routeName={"Employee Corner"}/>
        <MainLayoutRoutes exact path="/employee/:id" render={matchprops => (<CreateEmployeeCorner {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/employee/createNew" render={matchprops => (<CreateEmployeeCorner {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/content" render={matchprops => (<Content {...matchprops} />)} routeName="Content Management"/>
        <MainLayoutRoutes exact path="/content/:id" render={matchprops => (<CreateContent {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/content/createNew" render={matchprops => (<CreateContent {...matchprops} />)}/>  
        
        <MainLayoutRoutes exact path="/careers" render={matchprops => (<Career {...matchprops} />)} routeName="Careers Management"/>
        <MainLayoutRoutes exact path="/careers/:id" render={matchprops => (<CreateContent {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/careers/createNew" render={matchprops => (<CreateContent {...matchprops} />)}/>  
        
        <MainLayoutRoutes exact path="/project" render={matchprops => (<Project {...matchprops} />)} routeName={"Project Management"}/>
        <MainLayoutRoutes exact path="/project/edit" render={matchprops => (<CreateContent {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/project/createNew" render={matchprops => (<CreateContent {...matchprops} />)}/>
      
        <MainLayoutRoutes exact path="/category" render={matchprops => (<Category {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/category/:id" render={matchprops => (<CreateCategory {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/category/createNew" render={matchprops => (<CreateCategory {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/news_&_views" render={matchprops => (<NewsViews {...matchprops} />)} routeName="News & Views"/>
        <MainLayoutRoutes exact path="/news_&_views/:id" render={matchprops => (<CreateContent {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/news_&_views/createNew" render={matchprops => (<CreateContent {...matchprops} />)}/> 
        
        <MainLayoutRoutes exact path="/gallery" render={matchprops => (<Gallery {...matchprops} />)} routeName="Gallery Management"/>

        <MainLayoutRoutes exact path="/dashboard/manageGallery" render={matchprops => (<AddImages {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/dashboard/addFiles" render={matchprops => (<AddFiles {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/dashboard/addSeo" render={matchprops => (<AddSeo {...matchprops} />)}/>

        <MainLayoutRoutes exact path="/globalConfig" render={matchprops => (<GlobalConfig {...matchprops} />)} routeName={'Global Configuration'}/>


      </Switch>
      <Confirmation/>
      <HelpModal/>
    </Router>
  )
    ;
}

export default Routes;


// https://www.youtube.com/watch?v=Ll4JtXQ2ag0
// npm i react-otp-input
