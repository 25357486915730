import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {all, fork, put, takeEvery} from "redux-saga/effects";
import AuthService from "../../services/auth.service";
import HttpService from "../../services/http.service";

function* loginStart(action) {
  console.log('In loginStart authSagas.js ',action.payload);
  try {
    const response = yield AuthService.login({
      email: action.payload.email,
      password:action.payload.password
    });

    //console.log('response ',response.data);
    if (response.data.accessToken) {
      HttpService.setToken(response.data.accessToken);//set token in header
      let user = response?.data?.user || {}; 
      HttpService.setUser(user._id);// set userId and role in header
      //console.log('storing in localstorage ',user);
      localStorage.setItem('user', JSON.stringify(user)); 
      yield put(actions.loginSuccess(response.data));
    } else {
      yield put(actions.loginFailure("Invalid Username/Password"));
    }
  } catch (err) {
    console.log('err loginStart authSagas.js ', err)
    yield put(actions.loginFailure('Invalid Username/Password'));
  }
}

function* loginStartSaga() {
  yield takeEvery(actionsTypes.LOGIN_START, loginStart);
}

// function* otpGenStart(action) {
//   console.log('In otpGenStart Saga ',action.payload);
//   try {
//     const response = yield AuthService.login({
//       email: action.payload.email
//     });

//     if (response.data && response.data.statusCode === 200) { 
//       yield put(actions.genOtpSuccess(response.data));
//     } else {
//       yield put(actions.genOtpFailure("Invalid Username/Password"));
//     }
//   } catch (err) {
//     console.log('err ', err)
//     let error = err?.response?.data?.message;

//     if (!error) {
//       error = err?.response?.message || "Network error";
//     }
//     yield put(actions.genOtpFailure(error));
//   }
// }

// function* otpGenStartSaga() {
//   yield takeEvery(actionsTypes.OTP_GEN, otpGenStart);
// }

function* signUpStart(action) {
  try {
    let response = ''
    let payload = action.payload;
    let create = payload.create;
    delete payload.create
    if (create) {
      // create user flow
      delete payload.id
      response = yield AuthService.signup(payload);
    } else { 
      response = yield AuthService.editUser(payload);
    }  
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* signupStartSaga() {
  yield takeEvery(actionsTypes.SIGNUP_START, signUpStart);
}

function* getQueryList(action) {
  try {
    //console.log('getContentList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, search, status};

      const response = yield AuthService.listQuery(params);
      // console.log('getContentList response userSagas============ ',response);
      if (response?.data?.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.listQuerySuccess(response.data));
      } else {
        yield put(actions.listQueryFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.listQueryFailure(error));
  }
}
function* getQueryListSaga() {
  yield takeEvery(actionsTypes.LIST_QUERY_START, getQueryList);
}

function* manageConfig(action){
  try {
  console.log('manageConfig  payload ',action);

    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    // console.log('managePost GallerySagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield AuthService.manageConfig(payload);
    console.log('Submit resposne response config ============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data GallerySagas ',response.data);
      yield put(actions.manageConfigSuccess(response.data));
    } else {
      yield put(actions.manageConfigFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* manageConfigSaga() {
  yield takeEvery(actionsTypes.MANAGE_CONFIG_START, manageConfig);
}

function* getConfig(action){
  try {
    let response = yield AuthService.getConfig();
    console.log('Submit resposne response config ============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data GallerySagas ',response.data);
      yield put(actions.getConfigSuccess(response.data));
    } else {
      yield put(actions.getConfigFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.getConfigFailure(error));
  }
}

function* getConfigSaga() {
  yield takeEvery(actionsTypes.GET_CONFIG_START, getConfig);
}

export function* authSaga() {
  yield all([fork(loginStartSaga), fork(signupStartSaga), fork(getQueryListSaga),fork(manageConfigSaga),fork(getConfigSaga)]);
}
