import * as actionTypes from "../actionTypes";

export const getEmpCornerList = (data) => {
    return {
        type: actionTypes.GET_EMP_CORNER_LIST_START,
        payload: data,
    };
};
export const getEmpCornerListSuccess = (data) => {
    return {
        type: actionTypes.GET_EMP_CORNER_LIST_SUCCESS,
        payload: data,
    };
};
export const getEmpCornerListFailure = (data) => {
    return {
        type: actionTypes.GET_EMP_CORNER_LIST_FAILURE,
        payload: data,
    };
};

export const manageEmpCornerSuccess = (data) => {
    //console.log('managePostSuccess ',data);
    return {
        type: actionTypes.MANAGE_EMP_CORNER_SUCCESS,
        payload: data,
    };
};

export const manageEmpCornerFailure = (data) => {
    return {
        type: actionTypes.MANAGE_EMP_CORNER_FAILURE,
        payload: data,
    };
};

export const manageEmpCorner = (data) => {
    return {
        type: actionTypes.MANAGE_EMP_CORNER_START,
        payload: data,
    };
};


export const empCornerMessageHandler = () => {
    return {
        type: actionTypes.EMP_CORNER_MESSAGE_HANDLER,
        payload: null,
    };
};
