import React from 'react';
import {Grid} from '@material-ui/core'

export default function CustomTabs(props) {

  const {tabsList, activeTab, handleChange,  className} = props;

  return (
    <Grid container spacing={1} className={`pt-10 pb-10 ${className}`}>
      {(tabsList || []).map((tab, index) => (
        <Grid item key={tab + index} className="nav-item mouse-pointer margTop15" onClick={() => handleChange(tab)}>
          <text className={`nav-link ${activeTab?.value === tab?.value && ' active-nav-link'}`}
                href="#tab-1">{tab?.text}</text>
        </Grid>
      ))}
    </Grid>

  );
}
