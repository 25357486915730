import { 
  dashboardIcon, 
  dashboardIconActive, 
  setting,
  settingActive, 
  editor, editorActive,  business, businessActive,   training,trainingActive,empCorner,empCornerActive,examNoti,miscellanous,miscellanousActive,downloadDoc,
  pensionerInfo,  riverflowPak, riverflowPakActive, transferPostings, transferPostingsActive,  wapdaServices,wapdaServicesActive,cyberSecurity,wapdaRule,gmPowerTransfer,gmPowerTransferActive
} from '../assets/images/img';

let isLocal=false;
let frontendUrl="";
let backendUrl="";
 
if(isLocal){
  frontendUrl = `http://localhost:3001`; 
  backendUrl = `http://localhost:3004`;  
}
else{
  frontendUrl = `https://wapda.gov.pk`; 
  backendUrl= `https://wapda-api.rockvillegroup.com`; 
}
export const FRONTEND_DOMAIN_PREFIX =frontendUrl;
export const APP_DOMAIN_PREFIX = backendUrl;
export const TRACKING_ID = "G-QHQED5Z1NL";

 
export const API_URL = `${APP_DOMAIN_PREFIX}/api/v1`;
export const FILE_BASE_URL = 'http://localhost:3004/';
 
export const LABELS = {
  GO_BACK: '← Back',
  LOGOUT: '↶ Logout',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  REGISTER: 'Create User',
  EMAIL: 'Email Address',
  NAME: 'Username',
  FULL_NAME: 'Full Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  INVALID_MOBILE: 'Invalid mobile number'
};
export const PASSWORD = {
  passwordLength: 6,
  passwordLengthError: 'password is to short'
}
export const REGISTER = {
  SUCCESS_HEADER: "Success",
  SUCCESS_MESSAGE: "User Created Successfully!",
  FAILURE_HEADER: "Failure",
  FAILURE_MESSAGE: "Cannot Create User! User may already have been created with the given email!"
};
export const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2;3})+$/;
export const C_OTC_STORAGE = 'c_d_storage';


export const sidebarTabsList = [
  { text: 'dashboard', key: 'dashboard', icon: dashboardIcon, activeIcon: dashboardIconActive, role:['63314f7edd5ccc5c401ab036',"638d9b73ba5b9833dc8cbcd0","63314f7edd5ccc5c401ab035","63314f7edd5ccc5c407ab036"] },
  { text: 'user management', key: 'users', icon: gmPowerTransfer, activeIcon: gmPowerTransferActive, role:['63314f7edd5ccc5c401ab036'], routeName:'User Management'}, 
  { text: 'Content management', key: 'content', icon: editor, activeIcon: editorActive, role:['63314f7edd5ccc5c401ab036'], routeName:'Content Management' },
  { text: 'Category management', key: 'category', icon: setting, role:['63314f7edd5ccc5c401ab036'] },
  { text: 'News_&_Views', key: 'news_&_views', icon: miscellanous, activeIcon: miscellanousActive, role:['63314f7edd5ccc5c401ab036'], routeName:'News & Views' },
  { text: 'Gallery management', key: 'gallery', icon: training, activeIcon: trainingActive, role:['63314f7edd5ccc5c401ab036'], routeName:'Gallery Management' },

  { text: 'Business management', key: 'manageBusiness', icon: business, activeIcon: businessActive, role:['63314f7edd5ccc5c401ab036'], routeName:'Business Management'},
  { text: 'Project management', key: 'project', icon: wapdaServices, activeIcon: wapdaServicesActive, role:['63314f7edd5ccc5c401ab036','638d9b73ba5b9833dc8cbcd0'], routeName:'Project Management'},
  { text: 'Careers management', key: 'careers', icon: transferPostings, activeIcon: transferPostingsActive, role:['63314f7edd5ccc5c401ab036'], routeName:'Careers Management'},
  { text: 'Riverflow management', key: 'riverflow', icon: riverflowPak, activeIcon: riverflowPakActive, role:['63314f7edd5ccc5c401ab036'], routeName:"Riverflow Management" }, 
  { text: 'Employee corner', key: 'employee', icon: empCorner, activeIcon: empCornerActive, role:['63314f7edd5ccc5c401ab036','638d9b73ba5b9833dc8cbcd0'], 
    subItems:[
      {value:"6389a9c4ba7b4a6f2857fea4",text:"◉ Transfer & Posting Notifications"},
      {value:"6389a9c4ba7b4a6f2857fea8",text:"◉ Pay & Allowances Notifications"},
      {value:"6389a9c4ba7b4a6f2857fea9",text:"◉ Miscellaneous Notifications"},
      {value:"6389a9c4ba7b4a6f2857fea1",text:"◉ WAPDA Services"},
      {value:"6389a9c4ba7b4a6f3857fea2",text:"◉ Exams Notifications"},
      {value:"6389a9c4ba7b4a6f3857fea3",text:"◉ Pensioner's Information"},
      {value:"6389a9c4ba7b4a6f3857fea4",text:"◉ Download Documents"},
      {value:"6389a9c4ba7b4a6f3857fea8",text:"◉ Wapda Rules"},
      {value:"6389a9c4ba7b4a6f3857fea9",text:"◉ Cyber Security Alerts"},
      {value:"6389a9c4ba7b4a6f3857feb9",text:"◉ Tax Exemption Certificates"},
      {value:"6389a9c5ba6b4a8f3859feb9",text:"◉ Seniority List"}
    ]
  },  
  { text: 'Riverflow in Pakistan', key: 'riverflowPakistan', icon: riverflowPak, activeIcon: riverflowPakActive, role:['63314f7edd5ccc5c401ab036',"638d9b73ba5b9833dc8cbcd0","63314f7edd5ccc5c401ab035","63314f7edd5ccc5c407ab036"], routeName:"Riverflow in Pakistan"},  
  { text: 'General Information', key: 'business', icon: business, activeIcon: businessActive, role:['63314f7edd5ccc5c401ab036',"63314f7edd5ccc5c407ab036"], routeName:'General Information' },
  { text: 'Investor Queries', key: 'queries', icon: gmPowerTransfer, activeIcon: gmPowerTransferActive, role:["63314f7edd5ccc5c407ab036"] },
  { text: 'Global Configuration', key: 'globalConfig', icon: setting, activeIcon: settingActive, role:['63314f7edd5ccc5c401ab036'], routeName:'Global Configuration' },
  /////////Employeeeee////////////////
  { text: 'Employee corner', key: 'employee', icon: training, activeIcon: trainingActive, role:["63314f7edd5ccc5c401ab035"], 
    subItems:[
      {value:"6389a9c4ba7b4a6f2857fea4",text:"Transfer & Posting Notifications", icon:transferPostings},
      {value:"6389a9c4ba7b4a6f2857fea8",text:"Pay & Allowances Notifications", icon:empCorner},
      {value:"6389a9c4ba7b4a6f2857fea9",text:"Miscellaneous Notifications", icon:miscellanous},
      {value:"6389a9c4ba7b4a6f2857fea1",text:"WAPDA Services", icon:wapdaServices},
      {value:"6389a9c4ba7b4a6f3857fea2",text:"Exams Notifications", icon:examNoti},
      {value:"6389a9c4ba7b4a6f3857fea3",text:"Pensioner's Information", icon:pensionerInfo},
      {value:"6389a9c4ba7b4a6f3857fea4",text:"Download Documents", icon:downloadDoc},
      {value:"6389a9c4ba7b4a6f3857fea8",text:"Wapda Rules", icon:wapdaRule},
      {value:"6389a9c4ba7b4a6f3857fea9",text:"Cyber Security Alerts", icon:cyberSecurity},
      {value:"6389a9c4ba7b4a6f3857feb9",text:"Tax Exemption Certificates", icon:examNoti},
      {value:"6389a9c5ba6b4a8f3859feb9",text:"Seniority List"}

    ]
  },
];

export const jobStatus = [
  'pending', 'assigned', 'in route', 'delivered', 'completed',
];

export const userRoles = [ 
  {text: 'System Manager', value: "63314f7edd5ccc5c401ab036"},
  {text: 'Stakeholders', value: "638d9b73ba5b9833dc8cbcd0"},
  {text: 'Employee', value: "63314f7edd5ccc5c401ab035"},
  {text: 'Top Management', value: "63314f7edd5ccc5c407ab036"},
];

export const allowedMime=['PDF'];

export const rowsPerPageOption=[5,10,50,100];

export const userStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const categoryStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const postStatus = [
  { text: 'Published', value: '1' },
  { text: 'Unpublished', value: '0' },
]
export const yesNo = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 0 },
]

export const statusTypeOptions = [
  {text: 'All', value: ''},
  {text: 'Active', value: '1'},
  {text: 'In-Active', value: '0'},
]
export const postTypeOptions = [
  {text: 'All', value: ''},
  {text: 'Published', value: '1'},
  {text: 'Unpublished', value: '0'},
]

export const country = [
  {value: 'USA', text: 'United State'},
  {value: 'AF', text: 'Afghanistan'},
  {value: 'AL', text: 'Albania'},
  {value: 'DZ', text: 'Algeria'},
  {value: 'AS', text: 'American Samoa'},
  {value: 'AD', text: 'Andorra'},
  {value: 'AO', text: 'Angola'},
  {value: 'AI', text: 'Anguilla'},
  {value: 'AQ', text: 'Antarctica'},
  {value: 'AG', text: 'Antigua and/or Barbuda'},
  {value: 'AR', text: 'Argentina'},
  {value: 'AM', text: 'Armenia'},
  {value: 'AW', text: 'Aruba'},
  {value: 'AU', text: 'Australia'},
  {value: 'AT', text: 'Austria'},
  {value: 'AZ', text: 'Azerbaijan'},
  {value: 'BS', text: 'Bahamas'},
  {value: 'BH', text: 'Bahrain'},
  {value: 'BD', text: 'Bangladesh'},
  {value: 'BB', text: 'Barbados'},
  {value: 'BY', text: 'Belarus'},
  {value: 'BE', text: 'Belgium'},
  {value: 'BZ', text: 'Belize'},
  {value: 'BJ', text: 'Benin'},
  {value: 'BM', text: 'Bermuda'},
  {value: 'BT', text: 'Bhutan'},
  {value: 'BO', text: 'Bolivia'},
  {value: 'BA', text: 'Bosnia and Herzegovina'},
  {value: 'BW', text: 'Botswana'},
  {value: 'BV', text: 'Bouvet Island'},
  {value: 'BR', text: 'Brazil'},
  {value: 'IO', text: 'British lndian Ocean Territory'},
  {value: 'BN', text: 'Brunei Darussalam'},
  {value: 'BG', text: 'Bulgaria'},
  {value: 'BF', text: 'Burkina Faso'},
  {value: 'BI', text: 'Burundi'},
  {value: 'KH', text: 'Cambodia'},
  {value: 'CM', text: 'Cameroon'},
  {value: 'CV', text: 'Cape Verde'},
  {value: 'KY', text: 'Cayman Islands'},
  {value: 'CF', text: 'Central African Republic'},
  {value: 'TD', text: 'Chad'},
  {value: 'CL', text: 'Chile'},
  {value: 'CN', text: 'China'},
  {value: 'CX', text: 'Christmas Island'},
  {value: 'CC', text: 'Cocos Keeling} Islands'},
  {value: 'CO', text: 'Colombia'},
  {value: 'KM', text: 'Comoros'},
  {value: 'CG', text: 'Congo'},
  {value: 'CK', text: 'Cook Islands'},
  {value: 'CR', text: 'Costa Rica'},
  {value: 'HR', text: 'Croatia Hrvatska}'},
  {value: 'CU', text: 'Cuba'},
  {value: 'CY', text: 'Cyprus'},
  {value: 'CZ', text: 'Czech Republic'},
  {value: 'CD', text: 'Democratic Republic of Congo'},
  {value: 'DK', text: 'Denmark'},
  {value: 'DJ', text: 'Djibouti'},
  {value: 'DM', text: 'Dominica'},
  {value: 'DO', text: 'Dominican Republic'},
  {value: 'TP', text: 'East Timor'},
  {value: 'EC', text: 'Ecudaor'},
  {value: 'EG', text: 'Egypt'},
  {value: 'SV', text: 'El Salvador'},
  {value: 'GQ', text: 'Equatorial Guinea'},
  {value: 'ER', text: 'Eritrea'},
  {value: 'EE', text: 'Estonia'},
  {value: 'ET', text: 'Ethiopia'},
  {value: 'FK', text: 'Falkland Islands Malvinas}'},
  {value: 'FO', text: 'Faroe Islands'},
  {value: 'FJ', text: 'Fiji'},
  {value: 'FI', text: 'Finland'},
  {value: 'FR', text: 'France'},
  {value: 'FX', text: 'France, Metropolitan'},
  {value: 'GF', text: 'French Guiana'},
  {value: 'PF', text: 'French Polynesia'},
  {value: 'TF', text: 'French Southern Territories'},
  {value: 'GA', text: 'Gabon'},
  {value: 'GM', text: 'Gambia'},
  {value: 'GE', text: 'Georgia'},
  {value: 'DE', text: 'Germany'},
  {value: 'GH', text: 'Ghana'},
  {value: 'GI', text: 'Gibraltar'},
  {value: 'GR', text: 'Greece'},
  {value: 'GL', text: 'Greenland'},
  {value: 'GD', text: 'Grenada'},
  {value: 'GP', text: 'Guadeloupe'},
  {value: 'GU', text: 'Guam'},
  {value: 'GT', text: 'Guatemala'},
  {value: 'GN', text: 'Guinea'},
  {value: 'GW', text: 'Guinea-Bissau'},
  {value: 'GY', text: 'Guyana'},
  {value: 'HT', text: 'Haiti'},
  {value: 'HM', text: 'Heard and Mc Donald Islands'},
  {value: 'HN', text: 'Honduras'},
  {value: 'HK', text: 'Hong Kong'},
  {value: 'HU', text: 'Hungary'},
  {value: 'IS', text: 'Iceland'},
  {value: 'IN', text: 'India'},
  {value: 'ID', text: 'Indonesia'},
  {value: 'IR', text: 'Iran Islamic Republic of}'},
  {value: 'IQ', text: 'Iraq'},
  {value: 'IE', text: 'Ireland'},
  {value: 'IL', text: 'Israel'},
  {value: 'IT', text: 'Italy'},
  {value: 'CI', text: 'Ivory Coast'},
  {value: 'JM', text: 'Jamaica'},
  {value: 'JP', text: 'Japan'},
  {value: 'JO', text: 'Jordan'},
  {value: 'KZ', text: 'Kazakhstan'},
  {value: 'KE', text: 'Kenya'},
  {value: 'KI', text: 'Kiribati'},
  {value: 'KP', text: 'Korea, Democratic People\'s Republic of'},
  {value: 'KR', text: 'Korea, Republic of'},
  {value: 'KW', text: 'Kuwait'},
  {value: 'KG', text: 'Kyrgyzstan'},
  {value: 'LA', text: 'Lao People\'s Democratic Republic'},
  {value: 'LV', text: 'Latvia'},
  {value: 'LB', text: 'Lebanon'},
  {value: 'LS', text: 'Lesotho'},
  {value: 'LR', text: 'Liberia'},
  {value: 'LY', text: 'Libyan Arab Jamahiriya'},
  {value: 'LI', text: 'Liechtenstein'},
  {value: 'LT', text: 'Lithuania'},
  {value: 'LU', text: 'Luxembourg'},
  {value: 'MO', text: 'Macau'},
  {value: 'MK', text: 'Macedonia'},
  {value: 'MG', text: 'Madagascar'},
  {value: 'MW', text: 'Malawi'},
  {value: 'MY', text: 'Malaysia'},
  {value: 'MV', text: 'Maldives'},
  {value: 'ML', text: 'Mali'},
  {value: 'MT', text: 'Malta'},
  {value: 'MH', text: 'Marshall Islands'},
  {value: 'MQ', text: 'Martinique'},
  {value: 'MR', text: 'Mauritania'},
  {value: 'MU', text: 'Mauritius'},
  {value: 'TY', text: 'Mayotte'},
  {value: 'MX', text: 'Mexico'},
  {value: 'FM', text: 'Micronesia, Federated States of'},
  {value: 'MD', text: 'Moldova, Republic of'},
  {value: 'MC', text: 'Monaco'},
  {value: 'MN', text: 'Mongolia'},
  {value: 'MS', text: 'Montserrat'},
  {value: 'MA', text: 'Morocco'},
  {value: 'MZ', text: 'Mozambique'},
  {value: 'MM', text: 'Myanmar'},
  {value: 'NA', text: 'Namibia'},
  {value: 'NR', text: 'Nauru'},
  {value: 'NP', text: 'Nepal'},
  {value: 'NL', text: 'Netherlands'},
  {value: 'AN', text: 'Netherlands Antilles'},
  {value: 'NC', text: 'New Caledonia'},
  {value: 'NZ', text: 'New Zealand'},
  {value: 'NI', text: 'Nicaragua'},
  {value: 'NE', text: 'Niger'},
  {value: 'NG', text: 'Nigeria'},
  {value: 'NU', text: 'Niue'},
  {value: 'NF', text: 'Norfork Island'},
  {value: 'MP', text: 'Northern Mariana Islands'},
  {value: 'NO', text: 'Norway'},
  {value: 'OM', text: 'Oman'},
  {value: 'PK', text: 'Pakistan'},
  {value: 'PW', text: 'Palau'},
  {value: 'PA', text: 'Panama'},
  {value: 'PG', text: 'Papua New Guinea'},
  {value: 'PY', text: 'Paraguay'},
  {value: 'PE', text: 'Peru'},
  {value: 'PH', text: 'Philippines'},
  {value: 'PN', text: 'Pitcairn'},
  {value: 'PL', text: 'Poland'},
  {value: 'PT', text: 'Portugal'},
  {value: 'PR', text: 'Puerto Rico'},
  {value: 'QA', text: 'Qatar'},
  {value: 'SS', text: 'Republic of South Sudan'},
  {value: 'RE', text: 'Reunion'},
  {value: 'RO', text: 'Romania'},
  {value: 'RU', text: 'Russian Federation'},
  {value: 'RW', text: 'Rwanda'},
  {value: 'KN', text: 'Saint Kitts and Nevis'},
  {value: 'LC', text: 'Saint Lucia'},
  {value: 'VC', text: 'Saint Vincent and the Grenadines'},
  {value: 'WS', text: 'Samoa'},
  {value: 'SM', text: 'San Marino'},
  {value: 'ST', text: 'Sao Tome and Principe'},
  {value: 'SA', text: 'Saudi Arabia'},
  {value: 'SN', text: 'Senegal'},
  {value: 'RS', text: 'Serbia'},
  {value: 'SC', text: 'Seychelles'},
  {value: 'SL', text: 'Sierra Leone'},
  {value: 'SG', text: 'Singapore'},
  {value: 'SK', text: 'Slovakia'},
  {value: 'SI', text: 'Slovenia'},
  {value: 'SB', text: 'Solomon Islands'},
  {value: 'SO', text: 'Somalia'},
  {value: 'ZA', text: 'South Africa'},
  {value: 'GS', text: 'South Georgia South Sandwich Islands'},
  {value: 'ES', text: 'Spain'},
  {value: 'LK', text: 'Sri Lanka'},
  {value: 'SH', text: 'St. Helena'},
  {value: 'PM', text: 'St. Pierre and Miquelon'},
  {value: 'SD', text: 'Sudan'},
  {value: 'SR', text: 'Suri'},
  {value: 'SJ', text: 'Svalbarn and Jan Mayen Islands'},
  {value: 'SZ', text: 'Swaziland'},
  {value: 'SE', text: 'Sweden'},
  {value: 'CH', text: 'Switzerland'},
  {value: 'SY', text: 'Syrian Arab Republic'},
  {value: 'TW', text: 'Taiwan'},
  {value: 'TJ', text: 'Tajikistan'},
  {value: 'TZ', text: 'Tanzania, United Republic of'},
  {value: 'TH', text: 'Thailand'},
  {value: 'TG', text: 'Togo'},
  {value: 'TK', text: 'Tokelau'},
  {value: 'TO', text: 'Tonga'},
  {value: 'TT', text: 'Trinidad and Tobago'},
  {value: 'TN', text: 'Tunisia'},
  {value: 'TR', text: 'Turkey'},
  {value: 'TM', text: 'Turkmenistan'},
  {value: 'TC', text: 'Turks and Caicos Islands'},
  {value: 'TV', text: 'Tuvalu'},
  {value: 'UG', text: 'Uganda'},
  {value: 'UA', text: 'Ukraine'},
  {value: 'AE', text: 'United Arab Emirates'},
  {value: 'GB', text: 'United Kingdom'},
  {value: 'UM', text: 'United States minor outlying islands'},
  {value: 'UY', text: 'Uruguay'},
  {value: 'UZ', text: 'Uzbekistan'},
  {value: 'VU', text: 'Vanuatu'},
  {value: 'VA', text: 'Vatican City State'},
  {value: 'VE', text: 'Venezuela'},
  {value: 'VN', text: 'Vietnam'},
  {value: 'VG', text: 'Virgin Islands British}'},
  {value: 'VI', text: 'Virgin Islands U.S.}'},
  {value: 'WF', text: 'Wallis and Futuna Islands'},
  {value: 'EH', text: 'Western Sahara'},
  {value: 'YE', text: 'Yemen'},
  {value: 'YU', text: 'Yugoslavia'},
  {value: 'ZR', text: 'Zaire'},
  {value: 'ZM', text: 'Zambia'},
  {value: 'ZW', text: 'Zimbabwe'},
]
 
export const YesNoOption = [
  { text: 'Yes', value: '1' },
  { text: 'No', value: '0' },
]

export const ImageOption = [
  { text: 'Image', value: '1' },
  { text: 'Video', value: '2' },
]

export const FileOption = [
  { text: 'Embeded', value: '0' },
  { text: 'Download', value: '1' },
]
 
export function getFormattedDate(date) {
  const date1 = new Date(date);                 // {object Date}
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = date1.getFullYear();
  let month = monthNames[date1.getMonth()];
  let day = date1.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}
 
export const statusFailureCase = [3, 6, 8, 9, 11]
export const statusFailureCase1 = [5, 6, 7, 8, 9, 10, 11]

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const mobileNumberRegex = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,}$/im

 