import HttpService from "./http.service";

class ContentService extends HttpService {
    list = (params) => this.get("cms/list-post", params); 

    managePost = (params) => this.post("cms/manage-post", params); 

    manageSeo = (params) => this.post("cms/manage-seo", params);  

    getSeo = (params) => this.get("cms/get-seo", params); 

 
}

export default new ContentService();
