import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import EmpCornerService from '../../services/emp_corner.service'


function* getEmpCornerList(action) {
  try {
    console.log('getBusinessssListttt action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const category = action.payload?.category_id;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      const id = action.payload?.id;
      let params = {page, perPage, category, search, status, id};

      const response = yield EmpCornerService.listEmpCorner(params);
      //console.log('getContentList response userSagas============ ',response);
      if (response?.data) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.getEmpCornerListSuccess(response.data));
      } else {
        yield put(actions.getEmpCornerListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getEmpCornerListFailure(error));
  }
}

function* manageEmpCorner(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    //console.log('managePost contentSagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield EmpCornerService.manageEmpCorner(payload);
    console.log('manageEmpCorner response contentSagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data contentSagas ',response.data);
      yield put(actions.manageEmpCornerSuccess(response.data));
    } else {
      yield put(actions.manageEmpCornerFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.manageEmpCornerFailure(error));
  }
}
function* getEmpCornerListSaga() {
  yield takeEvery(actionsTypes.GET_EMP_CORNER_LIST_START, getEmpCornerList);
}

function* manageEmpCornerSaga(){
  yield takeEvery(actionsTypes.MANAGE_EMP_CORNER_START, manageEmpCorner);
}

export function* empCornerSaga() {
  yield all([fork(getEmpCornerListSaga),fork(manageEmpCornerSaga)]);
}
