import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  galleryList: null,
  galleryListError: null,
  galleryLoading: false,
  gallery: null,
  galleryDataError: null,
  success: null,
  error: null, 
};
export const assetReducer = (state = initialState, action) => {
  // console.log(action.type,' galleryReducer ',action.payload,'state',state);
  switch (action.type) {
    case actionTypes.GET_GALLERY_LIST_START:
      return { ...state, loading: true, galleryListError: null, galleryList: null,success:null};
    case actionTypes.GET_GALLERY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        galleryListError: null,
        galleryList: action.payload,
      };
    };
    case actionTypes.GET_GALLERY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        galleryListError: action.payload,
        galleryList: null,
      };

    case actionTypes.GET_GALLERY_DATA_START:
      return { ...state, galleryLoading: true, galleryDataError: null, gallery: null };
    case actionTypes.GET_GALLERY_DATA_SUCCESS:
      return {
        ...state,
        galleryLoading: false,
        galleryDataError: null,
        gallery: action.payload,
      };
    case actionTypes.GET_GALLERY_DATA_FAILURE:
      return {
        ...state,
        galleryLoading: false,
        galleryDataError: action.payload,
        gallery: null,
      };

    case actionTypes.MANAGE_GALLERY_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_GALLERY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 

    case actionTypes.DELETE_GALLERY_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'Delete Successfully',
        error: null
      };
    case actionTypes.DELETE_GALLERY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
 
    case actionTypes.MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        galleryListError: action.payload,
        galleryDataError: action.payload,
        error: action.payload,
        success: action.payload,
      };

    case actionTypes.MANAGE_VIDEO_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 

    case actionTypes.MANAGE_FILE_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_FILE_FAILURE:

    case actionTypes.GET_FILE_LIST_START:
      return { ...state, loading: true, fileListError: null, fileList: null,success:null};
    case actionTypes.GET_FILE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        fileListError: null,
        fileList: action.payload,
      };
    };
    case actionTypes.GET_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        fileListError: action.payload,
        fileList: null,
      };

    case actionTypes.DELETE_FILE_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'Delete Successfully',
        error: null
      };
    case actionTypes.DELETE_FILE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: null,
        };
   


    default:
      return { ...state };
  }
};
