import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {useStyles} from "../../container/sidebar/styles";
import HelpIcon from '@material-ui/icons/Help';
import {Tooltip} from "@material-ui/core";

export default function CustomSeparator(props) {


  const classes = useStyles();
  const {breadCrumbsList, helpHandler, location} = props;

  // const helpReducer = useSelector(state => state.helpReducer);
  // const dispatcher = useDispatch()

  const handleClick = (item, breadCrumbsList) => {
    if (item.url) {
      breadCrumbsList.pop()
      props.setBreadCrumbsList(breadCrumbsList)
      if(location.state)
        props.history.push({pathname: item.url, state: {...location.state}  });
      else props.history.push(item.url);
    }
  }
  return (
    <div className='spacer-breadcrumb'>
      <div>
        <div>
          <div className='text-capitalize breadcrumb-heading'>{breadCrumbsList[breadCrumbsList.length - 1].text}</div>
        </div>
        <div
          className='display-flex align-items-center justify-content-between'>
          <Breadcrumbs
            separator={<span className='separator'>/</span>} aria-label="breadcrumb"
          >
            {breadCrumbsList.map((item, index) => {
              if (index < breadCrumbsList.length - 1) {
                return (
                  <Link
                    key={`crumb${index}`}
                    color="inherit"
                    // href="/"
                    onClick={(e) => handleClick(item, breadCrumbsList)}
                    className='text-capitalize text-doc-none mouse-pointer breadcrumb-prev-link'>
                    {item.text}
                  </Link>
                )
              } else return (<span key={`crumb${index}`} className='text-capitalize breadcrumb-prev-link active-link'
              >{breadCrumbsList[breadCrumbsList.length - 1].text}</span>)
            })}

          </Breadcrumbs>
        </div>
      </div>
      <div>
        <Tooltip title={'help'}>
          <HelpIcon className='primary-green-color mouse-pointer' onClick={helpHandler}/>
        </Tooltip>
      </div>
    </div>
  );
}
