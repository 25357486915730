import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import AssetService from '../../services/asset.service'


function* getGalleryList(action) {
  try {
    //console.log('getGalleryList action userSagas============ ',action);
    if (action.payload) {
      const category_id = action.payload?.category_id;
      const post_id = action.payload?.post_id;
      const type = action.payload?.type;
      let params = {category_id, post_id,type};

      const response = yield AssetService.list(params);
      console.log('getGalleryList response gallerySagas============ ',response);
      if (response?.status === 200) {
        console.log('response.data GallerySaga ',response.data);
        yield put(actions.getGalleryListSuccess(response.data));
      } else {
        yield put(actions.getGalleryListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getGalleryListFailure(error));
  }
}

function* manageAsset(action){
  try {
    console.log('managePost GallerySagas  payload ',action);

    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    // console.log('managePost GallerySagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield AssetService.manageImage(payload);
    console.log('Submit resposne response gallerySagas ============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data GallerySagas ',response.data);
      yield put(actions.manageGallerySuccess(response.data));
    } else {
      yield put(actions.manageGalleryFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* manageVideoLink(action){
  try {
  console.log('managePost GallerySagas  payload ',action);

    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    // console.log('managePost GallerySagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield AssetService.manageVideoLink(payload);
    //console.log('managePost response GallerySagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data GallerySagas ',response.data);
      yield put(actions.manageVideoSuccess(response.data));
    } else {
      yield put(actions.manageVideoFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* deleteAsset(action){
  try {
  console.log('managePost GallerySagas  payload ',action);
    let response;
    let payload = action.payload;
    
    response = yield AssetService.deleteImage(payload);
    //console.log('managePost response GallerySagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data GallerySagas ',response.data);
      yield put(actions.deleteGallerySuccess(response.data));
    } else {
      yield put(actions.deleteGalleryFailure("network error"));
    }
  } catch (err) {
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    // yield put(actions.signupFailure(error));
  }
}


function* getGalleryListSaga() {
  yield takeEvery(actionsTypes.GET_GALLERY_LIST_START, getGalleryList);
}

function* manageGallerySaga(){
  yield takeEvery(actionsTypes.MANAGE_GALLERY_START, manageAsset);
}
function* manageVideoSaga(){
  yield takeEvery(actionsTypes.MANAGE_VIDEO_START, manageVideoLink);
}
function* deleteGallerySaga(){
  yield takeEvery(actionsTypes.DELETE_GALLERY_START, deleteAsset);
}

export function* gallerySaga() {
  yield all([fork(getGalleryListSaga),fork(manageGallerySaga),fork(manageVideoSaga),fork(deleteGallerySaga)]);
}
