import React, {useState} from "react";
import {Redirect, Route} from "react-router-dom";
// import internal(own) modules
import MainLayout from "./MainLayout";
import {
  C_OTC_STORAGE, 
  sidebarTabsList
} from "../environment";

const MainLayoutRoute = ({render, ...rest}) => {
  console.log('In MainLayoutRoute ')

  const [user] = useState(JSON.parse(localStorage.getItem(C_OTC_STORAGE)));
  const role = user.role;
  const sidebar =sidebarTabsList;
  const adminRoutes=['dashboard','users','content','category','news_&_views','gallery','manageBusiness','project','careers','riverflow','employee','riverflowPakistan','business','globalConfig'];
  const stakeRoutes=['/dashboard','/project','/project/edit','/dashboard/manageGallery','/dashboard/addFiles','/dashboard/addSeo','/employee','/riverflowPakistan'];
  const empRoutes=['/dashboard','/employee','/riverflowPakistan'];
  const topManagement=['dashboard','users','content','category','news_&_views','gallery','manageBusiness','project','careers','riverflow','employee','riverflowPakistan','business','queries','globalConfig'];
  //['/dashboard','/business','/queries','/riverflowPakistan'];
  //(adminRoutes.includes(rest.path.split('/')[1]) && (role === '1')) ||
  console.log(user.mod,"ur route isssss",rest.path.split('/'))
  
  return (
    user && role &&
    (
    ( adminRoutes.includes(rest.path.split('/')[1]) && (role._id == '63314f7edd5ccc5c401ab036')
      && !user.mod?.includes(rest.routeName)
    ) ||
    (stakeRoutes.includes(rest.path) && (role._id == '638d9b73ba5b9833dc8cbcd0')) ||
    (empRoutes.includes(rest.path) && (role._id == '63314f7edd5ccc5c401ab035')) ||
    (
      topManagement.includes(rest.path.split('/')[1]) && (role._id === '63314f7edd5ccc5c407ab036')
      && !user.mod?.includes(rest.routeName)
    )
    )
    
    ? 
    <Route
      {...rest}
      className={'app-logo-text'}
      render={matchProps => {
        return ( 
          <MainLayout sidebar={sidebar}>{render(matchProps)}</MainLayout> 
        )

      }}
    /> 
    :
    (<Redirect to={'/dashboard'}/>)
  );
};

export default MainLayoutRoute;
