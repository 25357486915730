
export const headCells = [
  {id: "date", numeric: false, disablePadding: true, label: "Date"},
  {id: "levels", numeric: false, disablePadding: false, label: "Levels"},
  {id: "inflow", numeric: false, disablePadding: false, label: "Inflow",},
  {id: "outflow", numeric: false, disablePadding: false, label: "Outflow",},
  {id: "lastyear_inflow", numeric: true, disablePadding: false, label: "Last year inflow"}, 
  {id: "last10year_inflow", numeric: true, disablePadding: false, label: "Last 10 year inflow"},  
  {id: "createdAt", numeric: false, disablePadding: false, label: "Created At"},
  {id: "action", numeric: true, disablePadding: false, label: "actions"},
];
   
export const reservoirsArr = [ 
  {title: 'Indus at Terbela', _id: "6389a9c4ba1b4a6f2857fe94"},
  {title: 'Kabul at Terbela', _id: "6389a9c4ba1b4a7f2857fe94"},
  {title: 'Jhelum at Mangla', _id: "6389a9c4ba1b4a8f2857fe94"},
  {title: 'Chenab Inflow at Marala', _id: "6389a9c4ba1b4a9f2857fe94"},
  {title: 'System Inflows', _id: "6389a9c5ba1b4a6f2857fe94"},
  {title: 'Kabul Inflow at Nowshera', _id: "6389a9c6ba1b4a6f2857fe94"},
  {title: 'Escapage below Kotri', _id: "6389a9c7ba1b4a6f2857fe94"},
];


export const riverFlowHeadCells = [
  {id: "date", numeric: false, disablePadding: true, label: "Date"},
  {id: "iatLevels", numeric: false, disablePadding: false, label: "Levels (Ft)"},
  {id: "iatInflow", numeric: false, disablePadding: false, label: "Inflow",},
  {id: "iatOutflow", numeric: false, disablePadding: false, label: "Outflow",},
  {id: "kInflowAN", numeric: true, disablePadding: false, label: "Kabul Inflow at Nowshera"}, 
  {id: "jamLevels", numeric: true, disablePadding: false, label: "Levels (Ft)"},  
  {id: "jamInflow", numeric: false, disablePadding: false, label: "Inflow",},
  {id: "jamOutflow", numeric: false, disablePadding: false, label: "Outflow",},
  {id: "cInflowAM", numeric: false, disablePadding: false, label: "Chenab Inflow at Marala",},
  {id: "sCurrY", numeric: false, disablePadding: false, label: "Current Year",},
  {id: "sLastY", numeric: false, disablePadding: false, label: "Last Year",},
  {id: "avgL10Y", numeric: false, disablePadding: false, label: "Average Last 10 Year",},
];
   

export const riverflowPreview = [ 
  {title: 'River Flow', _id: "1"},
  {title: 'Flow Below Kotri', _id: "2"},
  {title: 'Graphical Representation', _id: "3"}, 
];

export const previewTypeOptions = [ 
  {text: '2024', value: '2024'},
  {text: '2023', value: '2023'},
  {text: '2022', value: '2022'},
]