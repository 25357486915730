import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import BusinessService from '../../services/business.service'


function* getBusinessList(action) {
  try { 
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const type = action.payload?.type;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      const id = action.payload?.id;
      let params = {page, perPage, type, search, status, id};
      console.log('getBusinessList sagas============ ',params);
      const response = yield BusinessService.listBusiness(params);
      //console.log('getContentList response userSagas============ ',response);
      if (response?.data) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.getBusinessListSuccess(response.data));
      } else {
        yield put(actions.getBusinessListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getBusinessListFailure(error));
  }
}

function* manageBusiness(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    //console.log('managePost contentSagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield BusinessService.manageBusiness(payload);
    //console.log('managePost response contentSagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data contentSagas ',response.data);
      yield put(actions.manageBusinessSuccess(response.data));
    } else {
      yield put(actions.manageBusinessFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.manageBusinessFailure(error));
  }
}
function* getBusinessListSaga() {
  yield takeEvery(actionsTypes.GET_BUSINESS_LIST_START, getBusinessList);
}

function* manageBusinessSaga(){
  yield takeEvery(actionsTypes.MANAGE_BUSINESS_START, manageBusiness);
}

export function* businessSaga() {
  yield all([fork(getBusinessListSaga),fork(manageBusinessSaga)]);
}
