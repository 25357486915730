import * as actionTypes from "../actionTypes";

export const getParentCategoryList = (data) => {
    return {
        type: actionTypes.GET_PARENT_CATEGORY_LIST_START,
        payload: data,
    };
};
export const getParentCategoryListSuccess = (data) => {
    return {
        type: actionTypes.GET_PARENT_CATEGORY_LIST_SUCCESS,
        payload: data,
    };
};
export const getParentCategoryListFailure = (data) => {
    return {
        type: actionTypes.GET_PARENT_CATEGORY_LIST_FAILURE,
        payload: data,
    };
};

export const getChildCategoryList = (data) => {
    return {
        type: actionTypes.GET_CHILD_CATEGORY_LIST_START,
        payload: data,
    };
};
export const getChildCategoryListSuccess = (data) => {
    return {
        type: actionTypes.GET_CHILD_CATEGORY_LIST_SUCCESS,
        payload: data,
    };
};
export const getChildCategoryListFailure = (data) => {
    return {
        type: actionTypes.GET_CHILD_CATEGORY_LIST_FAILURE,
        payload: data,
    };
};

export const manageCategory = (data) => {
    return {
        type: actionTypes.MANAGE_CATEGORY_START,
        payload: data,
    };
};
export const manageCategorySuccess = (data) => {
    console.log('manageCategorySuccess ',data);
    return {
        type: actionTypes.MANAGE_CATEGORY_SUCCESS,
        payload: data,
    };
};
export const manageCategoryFailure = (data) => {
    return {
        type: actionTypes.MANAGE_CATEGORY_FAILURE,
        payload: data,
    };
};

export const updateCategory = (data) => {
    return {
        type: actionTypes.UPDATE_CATEGORY_START,
        payload: data,
    };
};
export const updateCategorySuccess = (data) => {
    return {
        type: actionTypes.UPDATE_CATEGORY_SUCCESS,
        payload: data,
    };
};
export const updateCategoryFailure = (data) => {
    return {
        type: actionTypes.UPDATE_CATEGORY_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => {
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};