import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  empCornerList: null,
  empCornerListError: null,
  empCornerLoading: false,
  empCorner: null,
  empCornerDataError: null,
  success: null,
  error: null, 
};
export const empCornerReducer = (state = initialState, action) => {
  //console.log(action.type,' contentReducer ',action.payload);
  switch (action.type) {
    case actionTypes.GET_EMP_CORNER_LIST_START:
      return { ...state, loading: true, empCornerListError: null, empCornerList: null };
    case actionTypes.GET_EMP_CORNER_LIST_SUCCESS: 
      return {
        ...state,
        loading: false,
        empCornerListError: null,
        empCornerList: action.payload,
      };
    
    case actionTypes.GET_EMP_CORNER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        empCornerListError: action.payload,
        empCornerList: null,
      };

    case actionTypes.MANAGE_EMP_CORNER_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_EMP_CORNER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload?.message,
        error: null
      };
    case actionTypes.MANAGE_EMP_CORNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 
    case actionTypes.EMP_CORNER_MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        empCornerListError: action.payload,
        empCornerDataError: action.payload,
        error: action.payload,
        success: action.payload,
      };
    default:
      return { ...state };
  }
};
