import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import AssetService from '../../services/asset.service'




function* manageAsset(action){
  try {
  console.log('managePost GallerySagas  payload ',action);

    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    // console.log('managePost GallerySagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield AssetService.manageFile(payload);
    if (response?.data.statusCode === 200) {
      //console.log('response.data GallerySagas ',response.data);
      yield put(actions.manageFileSuccess(response.data));
    } else {
      yield put(actions.manageFileFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* getFileList(action) {
    try {
      //console.log('getGalleryList action userSagas============ ',action);
      if (action.payload) {
        const category_id = action.payload?.category_id;
        const post_id = action.payload?.post_id;
        const business_id = action.payload?.business_id;
        const employee_corner_id = action.payload?.employee_corner_id; 
        const type = action.payload?.type;
        let params = {category_id, post_id,business_id,employee_corner_id,type};
  
        const response = yield AssetService.listFiles(params);
        console.log('getGalleryList response gallerySagas============ ',response);
        if (response?.status === 200) {
          console.log('response.data GallerySaga ',response.data);
          yield put(actions.getFileListSuccess(response.data));
        } else {
          yield put(actions.getFileListFailure("network error"));
        }
      }
    } catch (err) {
      let error = err?.response?.data?.message
      if (!error) {
        error = 'network error'
      }
      yield put(actions.getGalleryListFailure(error));
    }
}

function* deleteFile(action){
    try {
    console.log('managePost GallerySagas  payload ',action);
      let response;
      let payload = action.payload;
      
      response = yield AssetService.deleteFile(payload);
      //console.log('managePost response GallerySagas============ ',response);
      if (response?.data.statusCode === 200) {
        //console.log('response.data GallerySagas ',response.data);
        yield put(actions.deleteFileSuccess(response.data));
      } else {
        yield put(actions.deleteFileFailure("network error"));
      }
    } catch (err) {
      let error = err?.response?.data?.message;
      if (!error) {
        error = "network error"
      }
      // yield put(actions.signupFailure(error));
    }
  }


function* manageFileSaga(){
    yield takeEvery(actionsTypes.MANAGE_FILE_START, manageAsset);
}

function* listFileSaga(){
    yield takeEvery(actionsTypes.GET_FILE_LIST_START, getFileList);
}
  
function* deleteFileSaga(){
    yield takeEvery(actionsTypes.DELETE_FILE_START,deleteFile);
}



export function* fileUploadSaga() {
  yield all([fork(manageFileSaga),fork(listFileSaga),fork(deleteFileSaga)]);
}
