import userIcon from "./user-man.svg"; 
import userProfile from './default-welcm-img.png' 
import PaymentIcon from './pngwing.png';
import finance from './undraw_finance_re_gnv2.svg'
import wapdaLogo from '../logo.png'
import setting from "./setting.svg";
import settingActive from "./setting-active.svg"; 
import contract from "./contract.svg"; 
import dashboardIcon from './designers/dashboard.svg';
import dashboardIconActive from './designers/dashboard-active.svg';
import downArrow from './designers/down-arrow.svg'
import burgerIcon from './designers/burger-icon.svg'
import hydroRes from "./designers/hydro-res.svg"; 
import business from "./designers/business.svg"; 
import businessActive from "./designers/business-active.svg"; 
import editor from "./designers/editor.svg"; 
import editorActive from "./designers/editor-active.svg"; 
import hydroPower from "./designers/hydro-power.svg"; 
import research from "./designers/research.svg"; 
import training from "./designers/training.svg";
import trainingActive from "./designers/training-active.svg"; 
import empCorner from "./designers/emp-corner.svg"; 
import empCornerActive from "./designers/emp-corner-active.svg"; 
import examNoti from "./designers/exam-noti.svg"; 
import miscellanous from "./designers/miscellanous.svg";
import miscellanousActive from "./designers/miscellanous-active.svg"; 
import downloadDoc from "./designers/download-doc.svg"; 
import pensionerInfo from "./designers/pensioner-info.svg"; 
import riverflowPak from "./designers/riverflow-pak.svg"; 
import riverflowPakActive from "./designers/riverflow-pak-active.svg"; 
import transferPostings from "./designers/transfer-postings.svg"; 
import transferPostingsActive from "./designers/transfer-postings-active.svg"; 
import wapdaServices from "./designers/wapda-services.svg"; 
import wapdaServicesActive from "./designers/wapda-services-active.svg"; 
import cyberSecurity from "./designers/cyber-security.svg"; 
import wapdaRule from "./designers/wapda-rule.svg"; 
import gmPowerTransfer from "./designers/gm-power-transfer.svg";
import gmPowerTransferActive from "./designers/gm-power-transfer-active.svg"; 
import logoWhite from './white-logo.png'
import logoBlack from './black-logo.png'
import subTabIcon from './SubTabIcon.svg'
import activeSubTabIcon from './ActiveSubTabIcon.svg'

const menuIcon = burgerIcon;

export {
  dashboardIcon,
  dashboardIconActive,
  userIcon, 
  menuIcon,
  userProfile,
  downArrow,
  PaymentIcon,
  finance,
  wapdaLogo,
  setting,
  settingActive,
  contract,
  editor, editorActive, hydroRes, business, businessActive, hydroPower,  research,  training,trainingActive,empCorner,empCornerActive,examNoti,miscellanous,miscellanousActive,downloadDoc,
  pensionerInfo,  riverflowPak, riverflowPakActive, transferPostings, transferPostingsActive, wapdaServices,wapdaServicesActive,cyberSecurity,wapdaRule,gmPowerTransfer,gmPowerTransferActive,logoWhite,logoBlack,subTabIcon,
  activeSubTabIcon
}
