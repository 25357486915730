import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  businessList: null,
  businessListError: null,
  businessLoading: false,
  business: null,
  businessDataError: null,
  success: null,
  error: null, 
};
export const businessReducer = (state = initialState, action) => {
  //console.log(action.type,' contentReducer ',action.payload);
  switch (action.type) {
    case actionTypes.GET_BUSINESS_LIST_START:
      return { ...state, loading: true, businessListError: null, businessList: null };
    case actionTypes.GET_BUSINESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        businessListError: null,
        businessList: action.payload,
      };
    
    case actionTypes.GET_BUSINESS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        businessListError: action.payload,
        businessList: null,
      };

    case actionTypes.MANAGE_BUSINESS_START:
      return { ...state, loading: true, success: null, error: null };
    case actionTypes.MANAGE_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case actionTypes.MANAGE_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,   
        success: null,
      }; 
    case actionTypes.BUSINESS_MESSAGE_HANDLER:
      return {
        ...state,
        loading: false,
        businessListError: action.payload,
        businessDataError: action.payload,
        error: action.payload,
        success: action.payload,
      };
    default:
      return { ...state };
  }
};
